import { FILE_RESOURCE_BASE_FRAGMENT } from '@/graphql/_Fragments/FileResource/Base';

export const SALES_PACKAGE_ENTITY_LIST_FRAGMENT = `
  fragment salesPackageEntityListFragment on SalesPackage {
    __typename
    uid
    title
    shortDescription
    fileResource {
      ...fileResourceBaseFragment
    }
  }
  ${FILE_RESOURCE_BASE_FRAGMENT}
`;
